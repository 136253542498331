<template>
  <Tooltip2Vue
    :hover="showTooltip"
    :position="position"
    :deleteTooltip="!showTooltip"
    class="tooltip-avatars"
  >
    <vs-avatar-group :max="max">
      <vs-avatar
        style="z-index: 1"
        v-for="(item, index) in visibleAvatars"
        :key="index"
        size="35"
        :class="`lego-vs-avatar bg-${index + 1}`"
        v-b-tooltip.hover="index < max"
        :title="index < max - 1 ? item.name : ''"
        @mouseover="index == max - 1 ? handleShowTooltip(true) : null"
        @mouseleave="index == max - 1 ? handleShowTooltip(false) : null"
      >
        <template #text>
          {{ item.name || "" }}
        </template>
      </vs-avatar>
    </vs-avatar-group>
    <template #tooltip>
      <div
        v-for="item in hiddenAvatars"
        :key="item.id"
        class="d-flex align-items-center p-2"
        style="gap: 0.5rem"
      >
        <i class="fas fa-user"></i>
        <span>{{ item.name }} </span>
      </div>
    </template>
  </Tooltip2Vue>
</template>

<script>
import Tooltip2Vue from "@/components/Tooltip2Vue/Tooltip2Vue.vue";
export default {
  props: {
    listAvatars: {
      type: Array,
      required: true,
      default: () => [],
    },
    position: {
      type: String,
      required: false,
      default: "right",
    },
    max: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  components: {
    Tooltip2Vue,
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    handleShowTooltip(bool) {
      this.showTooltip = bool;
    },
  },
  computed: {
    visibleAvatars() {
      // console.log("visibleAvatars", this.listAvatars);

      return this.listAvatars;
    },
    hiddenAvatars() {
      return this.listAvatars.slice(this.max);
    },
  },
};
</script>
