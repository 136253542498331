<script>
import BoardResumen from "@/components/Lego/BoardResumen.vue";

export default {
  components: {
    BoardResumen,
  },
  props: {
    boards: {
      type: Array,
      default: () => [],
    },

    title: {
      type: String,
      default: "Tus tableros",
    },

    hiddenSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCards: true,
      isFocusText: false,
      inputSearch: "",
      inputSelect: null,
    };
  },
  computed: {
    filteredBoards() {
      if (this.boards) {
        console.log(this.boards);

        return this.boards.filter((board) => {
          const condition1 = board.title
            .toLowerCase()
            .includes(this.inputSearch.toLowerCase());

          let condition2;
          if (this.inputSelect) {
            if (this.inputSelect == "global") {
              condition2 = board.isGlobalTemplate;
            } else if (this.inputSelect == "empresa") {
              condition2 = board.empresaTemplateId;
            } else if (this.inputSelect == "privado") {
              condition2 = !board.empresaTemplateId && !board.isGlobalTemplate;
            }
          } else {
            condition2 = true;
          }
          return condition1 && condition2;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    toFocusText() {
      this.isFocusText = true;
      const input = document.getElementById(`search${this.title}`);
      input.focus();
    },
    onFocus() {
      console.log("grito");
    },
  },
};
</script>

<template>
  <article class="wrapper-section">
    <header>
      <div class="header-left">
        <h5 class="title">
          {{ title }}
        </h5>
      </div>

      <div class="controls">
        <div class="inputs">
          <div
            class="wrapper-input-text"
            :class="{ focus: isFocusText }"
            @click="toFocusText"
          >
            <input
              v-model="inputSearch"
              @blur="isFocusText = false"
              type="text"
              class="input"
              placeholder="Buscar"
              :id="`search` + title"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="icon-search"
            >
              <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
              />
            </svg>
          </div>

          <select class="input" v-model="inputSelect" v-if="!hiddenSelect">
            <option value="privado">Tus Plantillas</option>
            <option :value="null">Todas las plantillas</option>
            <option value="global">Plantillas globales</option>
            <option value="empresa">Plantillas de la empresa</option>
          </select>
        </div>

        <div class="toggle-cards" v-if="false">
          <div @click="isCards = false" v-show="isCards">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="icon"
            >
              <path
                d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"
              />
            </svg>
          </div>
          <div @click="isCards = true" v-show="!isCards">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="icon"
            >
              <path
                d="M448 96V224H288V96H448zm0 192V416H288V288H448zM224 224H64V96H224V224zM64 288H224V416H64V288zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
              />
            </svg>
          </div>
        </div>
      </div>
    </header>
    <div class="cards">
      <slot name="defaultBoard"> </slot>
      <BoardResumen
        v-show="filteredBoards.length"
        v-for="board in filteredBoards"
        :key="board.id"
        :board="board"
      />
      <b-skeleton
        v-show="!filteredBoards.length"
        v-for="board in 7"
        :key="board"
        class="lego-card-skeleton"
        width="100%"
        height="100%"
      ></b-skeleton>
    </div>
  </article>
</template>

<style scoped>
.title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.wrapper-section {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  min-height: 400px;
  background-color: var(--lego-dbg);
  box-shadow: 0 0 4px 2px var(--lego-shadow);
  grid-column: span 2;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem;
  /* background-color: red; */
  margin-bottom: 0.5rem;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.icon {
  width: 18px;
  fill: silver;
  cursor: pointer;
}

.controls,
.header-left {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.wrapper-input-text {
  box-shadow: 0 1px 0px 0px rgba(255, 255, 255, 0.3);
  border-collapse: collapse;
  padding-right: 8px;
}
.wrapper-input-text.focus {
  background-color: var(--lego-shadow-hover);
}

.icon-search {
  width: 14px;
  fill: var(--lego-gris);
  cursor: pointer;
}

select.input {
  box-shadow: 0 1px 0px 0px rgba(255, 255, 255, 0.3);
  /* background-color: #14141B; */
  background-color: var(--gray-300);
}

select.input:focus {
  background-color: var(--white);
}

.input {
  border: none;
  padding: 5px 10px;
  background-color: transparent;
  box-sizing: border-box;
  color: var(--lego-gris);
  transition: 0.3s;
}

.input::placeholder {
  color: var(--lego-gris);
}
input.input {
  padding-bottom: 6px;
}

.inputs {
  display: flex;
  gap: 1rem;
}
</style>
