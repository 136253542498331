<script>
export default {
  props: {
    // array
    position: {
      type: String,
      default: "top",
    },
    deleteTooltip: {
      type: Boolean,
      default: false,
    },
    dev: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nativeShow: this.dev ? true : false,
      placement: this.position,
      outShow: false,
    };
  },
  computed: {
    show() {
      return this.nativeShow || this.outShow;
    },
  },
  mounted() {
    this.setBasicPosition();
  },
  methods: {
    handleDocumentBody(ev) {
      // console.log(ev);
      if (!this.$el.contains(ev.target)) {
        this.close();
      }
    },
    close() {
      this.nativeShow = false;
      document.removeEventListener("click", this.handleDocumentBody);
    },
    closeBlur(ev) {
      if (this.$el.contains(ev.relatedTarget)) return;
      this.nativeShow = false;
      document.removeEventListener("click", this.handleDocumentBody);
    },

    onFocus() {
      if (!this.nativeShow) {
        document.addEventListener("click", this.handleDocumentBody);
      }
      this.nativeShow = true;
      this.setBasicPosition();
      this.setResponsivePosition();
    },
    onOver() {
      if (this.hover) {
        this.nativeShow = true;
        this.setBasicPosition();
        this.setResponsivePosition();
      }
    },
    onOut() {
      if (this.hover) {
        this.nativeShow = false;
      }
    },

    setBasicPosition() {
      const tp = this.$el.querySelector(".tp");

      // default
      if (this.placement == "bottom") {
        tp.style.top = "105%";
        tp.style.bottom = "auto";
        tp.style.left = "50%";
        tp.style.transform = "translate(-50%, 0)";
      } else if (this.placement == "bottom-start") {
        tp.style.top = "105%";
        tp.style.bottom = "auto";
        tp.style.left = "auto";
        tp.style.right = "100%";
        tp.style.transform = "translate(100%, 0)";
      } else if (this.placement == "bottom-end") {
        tp.style.top = "105%";
        tp.style.bottom = "auto";
        tp.style.left = "100%";
        tp.style.right = "auto";
        tp.style.transform = "translate(-100%, 0)";
      } else if (this.placement == "top") {
        tp.style.top = "auto";
        tp.style.bottom = "105%";
        tp.style.left = "50%";
        tp.style.transform = "translate(-50%, 0)";
      } else if (this.placement == "top-start") {
        tp.style.top = "auto";
        tp.style.bottom = "105%";
        tp.style.left = "auto";
        tp.style.right = "100%";
        tp.style.transform = "translate(100%, 0)";
      } else if (this.placement == "top-end") {
        tp.style.top = "auto";
        tp.style.bottom = "105%";
        tp.style.left = "100%";
        tp.style.right = "auto";
        tp.style.transform = "translate(-100%, 0)";
      } else if (this.placement == "left") {
        tp.style.top = "50%";
        tp.style.bottom = "auto";
        tp.style.left = "auto";
        tp.style.right = "105%";
        tp.style.transform = "translate(0, -50%)";
      } else if (this.placement == "left-start") {
        tp.style.top = "auto";
        tp.style.bottom = "100%";
        tp.style.left = "auto";
        tp.style.right = "105%";
        tp.style.transform = "translate(0, 100%)";
      } else if (this.placement == "left-start-out") {
        tp.style.top = "auto";
        tp.style.bottom = "100%";
        tp.style.left = "auto";
        tp.style.right = "105%";
        tp.style.transform = "translate(0, 0)";
      } else if (this.placement == "left-end") {
        tp.style.top = "100%";
        tp.style.bottom = "auto";
        tp.style.left = "auto";
        tp.style.right = "105%";
        tp.style.transform = "translate(0, -100%)";
      } else if (this.placement == "left-end-out") {
        tp.style.top = "100%";
        tp.style.bottom = "auto";
        tp.style.left = "auto";
        tp.style.right = "105%";
        tp.style.transform = "translate(0, 0)";
      } else if (this.placement == "right") {
        tp.style.top = "50%";
        tp.style.bottom = "auto";
        tp.style.left = "105%";
        tp.style.right = "auto";
        tp.style.transform = "translate(0, -50%)";
      } else if (this.placement == "right-start") {
        tp.style.top = "auto";
        tp.style.bottom = "100%";
        tp.style.left = "105%";
        tp.style.right = "auto";
        tp.style.transform = "translate(0, 100%)";
      } else if (this.placement == "right-start-out") {
        tp.style.top = "auto";
        tp.style.bottom = "100%";
        tp.style.left = "105%";
        tp.style.right = "auto";
        tp.style.transform = "translate(0, 0)";
      } else if (this.placement == "right-end") {
        tp.style.top = "100%";
        tp.style.bottom = "auto";
        tp.style.left = "105%";
        tp.style.right = "auto";
        tp.style.transform = "translate(0, -100%)";
      } else if (this.placement == "right-end-out") {
        tp.style.top = "100%";
        tp.style.bottom = "auto";
        tp.style.left = "105%";
        tp.style.right = "auto";
        tp.style.transform = "translate(0, 0)";
      }
    },
    setResponsivePosition() {
      // salvamos el ancho de la pantalla
      const width = window.innerWidth;
      // const height = window.innerHeight
      // console.log('width', width, 'height', height);

      // salvamos la posicion del componente y su tamaño

      const rect = this.$el.getBoundingClientRect();
      const wPop = rect.width;
      // const top = rect.top
      const relativeLeft = rect.left;
      // const top = rect.top + window.scrollY
      // const left = rect.left + window.scrollX
      // console.log('top', top, 'left', relativeLeft);
      // console.log('rect', rect);

      // salvamos el tamaño del tooltip que tiene la clase tp

      const tp = this.$el.querySelector(".tp");
      const wtp = tp.offsetWidth;

      if (!this.placement.includes("left")) {
        if (relativeLeft + wPop + wtp / 2 + 5 > width) {
          this.placement = "bottom-end";
        }
      }
    },
    setOutShow(value) {
      this.outShow = value;
    },
  },
};
</script>

<template>
  <button
    @mouseover="onOver"
    @mouseout="onOut"
    @focus="onFocus"
    @blur="closeBlur"
    tabindex="0"
    class="popper"
  >
    <slot>
      <div>
        <div class="btn btn-primary">div</div>
      </div>
    </slot>
    <div :class="{ show: show }" class="tp" v-if="!deleteTooltip">
      <slot name="tooltip" :close="close" :isShow="nativeShow">
        <div class="tp-default">tooltip</div>
      </slot>
    </div>
  </button>
</template>

<style scoped>
.popper {
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
}

.tp {
  visibility: hidden;
  position: absolute;
  width: max-content;
  max-width: 320px;
  opacity: 0;
  border-radius: 0.25rem;
  z-index: 100000;
  transition: 0.3s ease-in-out;
  cursor: auto;

  .tooltip-avatars & {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: solid 1px #cacaca;
    .dark & {
      border: solid 1px #2d2d2d;
    }
  }
}

.tp.show {
  visibility: visible;
  opacity: 1;
  background-color: var(--lego-bg);
  color: silver;
}

.tp-default {
  padding: 0.5rem;
}
</style>
